<template>
    <div>
        <Header />
        <mdb-parallax style="margin-top: -13px"
            src="/img/bgs/bg8.jpg"
            height="full"
            factor="1"
            >
            <mdb-mask overlay="blue-slight" flexCenter>
                <mdb-row class="mb-5 pb-5 text-center white-text">
                <mdb-col md="12" xl="12" class="mx-auto">
                    <h1 class="display-4 font-weight-bold animated fadeInDown">Misión y Visión</h1>
                </mdb-col>
                </mdb-row>
            </mdb-mask>
        </mdb-parallax>
        <mdb-container>
            <mdb-row class="mt-5 pt-5 mb-5">
                <mdb-col lg="6" md="12" sm="12" class="mb-5">
                    <img src="/img/mission.jpg" class="img-fluid mv-img" />
                    <div class="mv-container" style="max-height: 550px;">
                        <p class="text-justify ar-text"> <b style="font-weight: 900">MISIÓN:</b> En Arvore, nos comprometemos a desarrollar e implementar soluciones innovadoras que aborden los desafíos ambientales actuales. Nuestra misión es preservar y proteger la biodiversidad, mitigar el cambio climático y promover la sostenibilidad a través de la colaboración con empresas, comunidades y otros actores clave. Todo esto,  impulsado por la pasión por el medio ambiente y el compromiso con la responsabilidad ambiental. Nos esforzamos por ser líderes en nuestro campo, brindando soluciones que marquen una diferencia real en la protección del entorno natural y la creación de un futuro más sostenible para todos.</p>
                    </div>
                </mdb-col>
                <mdb-col lg=6 md="12" sm="12" class="mb-5">
                    <img src="/img/vision.jpg" class="img-fluid mv-img" />
                    <div class="mv-container" style="max-height: 550px;">
                        <p class="text-justify ar-text"><b style="font-weight: 900">VISIÓN:</b> Ser líderes en la promoción de un mundo sostenible, donde el desarrollo humano y la preservación del entorno natural coexistan en armonía. Nuestra visión es construir un futuro en el que la biodiversidad sea prioridad, el cambio climático anecdota y las generaciones venideras disfruten de un ambiente más resiliente y adecuado.</p>
                    </div>
                </mdb-col>
            </mdb-row>
        </mdb-container>
        <Footer />
    </div>
</template>
<script>
    import { mdbContainer, mdbRow, mdbCol, mdbIcon, mdbCard, mdbMask, mdbParallax } from "mdbvue";
    import Header from "./Header";
    import Footer from "./Footer";

    export default {
        name: "Services",
        components: {
            mdbContainer,
            mdbRow,
            mdbCol, 
            Header,
            Footer,
            mdbIcon,
            mdbCard,
            mdbMask,
            mdbParallax
        }
    }
</script>
<style>
    .mv-container {
        display: block;
        position: relative;
        color: white;
        height: 100%;
        padding: 50px;
        border-radius: 0px 0px 10px 10px;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    }

    .mv-container h3 {
        text-transform: uppercase;
        font-weight: bold;
    }

    .mv-container p {
        line-height: 2rem;
        font-size: 18px;
    }

    .img-mision {
        position: absolute;
        top: -100px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 200px;
        height: auto;
    }

    .img-vision {
        bottom: 0;
        left: 0;
        margin-left: -50px;
        margin-bottom: -50px;
        max-width: 200px;
    }

    .mv-img {
        border-radius: 10px 10px 0px 0px;
        width: 100%;
    }

    .value-option {
        font-size: 22px;
        transition: all .5s;
        color: white;
        font-weight: bold;
        display: inline-block;
        padding: 10px 20px;
        text-transform: uppercase;
        width: 100%;
        border-radius: 5px;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0) scale(1.0, 1.0);
    }


     @media(max-width: 1199px){
         .mv-container {
             height: 100%;
         }

        .mv-container p {
            font-size: 15px;
        }
     }

     @media(max-width: 991px){
         .mv-container {
             height: auto;
         }

        .mv-container p {
            font-size: 15px;
            line-height: 1rem;
        }
     }
</style>
